<div class="mx-auto max-w-xl px-2">
	<h2 class="text-2xl text-center text-gfx-primary dark:text-gfx-accent">Log In to GFX</h2>
	<div class="mt-4 grid grid-cols-2 gap-4">
		<div>
			<div class="text-xl">Sign In</div>
			<div class="text-lg mt-4">Have an account?</div>
			<button
				mat-flat-button
				class="mt-4 flex-none gfx-button-secondary text-slate-900"
				(click)="login()"
				[disabled]="requestPending">
				Sign In
			</button>
			<img src="assets/images/gfx-heartbeat.webp" alt="GFX Logo" class="w-32 h-32" alt="Group Fitness X logo" />
		</div>
		<div>
			<div class="text-xl">Create An Account</div>
			<button
				mat-flat-button
				class="mt-4 gfx-button-secondary text-slate-900"
				(click)="createAccount()"
				[disabled]="requestPending">
				Create Account
			</button>
			<h3 class="mt-6 font-bold">Join For Free With A 30-Day Trial</h3>
			<p class="ml-6">
				Enjoy all of the
				<a class="underline text-gfx-primary dark:text-purple-400" [href]="marketingSiteUrl + '/pricing'">features</a>
				of GFX with no payment method required to get started.
			</p>
			<div class="mt-4 text-lg font-bold">Find Release Tracks</div>
			<p class="ml-6">Search by song, music artist, exercises, and more.</p>
			<div class="mt-4 text-lg font-bold">Create your own class playlists</div>
			<p class="ml-6">Create from a previous release, take inspiration from the community, or create your own.</p>
			<div class="mt-4 text-lg font-bold">Share your playlists</div>
			<p class="ml-6">Easily post your class playlists on social media to keep your class participants up to date.</p>
		</div>
	</div>
</div>
